/***** Variables *****/
    $dark-purple: #450F2E;
    $semi-purple: #5E1134;
    $purple: #A51D32;
    $dark-blue: #281548;
    $text: #1C1823;
    $nearly-white: #F8F7FF;
    $leegpng: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQI12NgYAAAAAMAASDVlMcAAAAASUVORK5CYII=);

/***** Widths *****/
    @for $i from 1 through 12 {
        $value: $i;
        .col#{$value}-12 {
            width: percentage($value/12) !important;
        }
    }
	.colbreak {
		width: 100%;
		clear: both;
		padding: 0px !important;
	}

    @media all and (max-width: 1023px) {
        // in 3's
        .col1-12, .col2-12, .col3-12, .col4-12 {
            width: percentage(1/3) !important;
        }
        .col5-12, .col6-12, .col7-12 {
            width: percentage(1/2) !important;
        }
        .col8-12, .col9-12 {
            width: percentage(2/3) !important;
        }
        .col10-12, .col11-12, .col12-12 {
            width: 100% !important;
        }

        .blockstyle-margin {
            display: none !important;
        }
    }

    @media all and (max-width: 768px) {
        // in 2's
        .col1-12, .col2-12, .col3-12, .col4-12, .col5-12, .col6-12, .col7-12 {
            width: percentage(1/2) !important;
        }
        .col8-12, .col9-12, .col10-12, .col11-12, .col12-12 {
            width: 100% !important;
        }
    }

    @media all and (max-width: 450px) {
        // in 1's
        .block-content {
            width: 100% !important;
        }
    }

/***** Blocks *****/
    .block-content {
        float: left;
        display: inline-block;
        width: 100%;
        padding: 25px;
        text-align: justify;

        h1, h2, h3, h4, h5, h6 {
            font-family: 'Comfortaa';
            //font-weight: normal !important;
            margin-bottom: 20px;
        }
        a {
            text-decoration: none;

            &:hover, &:focus {
                text-decoration: underline;
            }
        }
    }
    .blockstyle-transparent {
        background: transparent;
        color: $text;
        h1, h2, h3, h4, h5, h6, a {
            color: $purple;
        }
        a {
            color: lighten($purple, 10%);
        }
    }
    .blockstyle-dark-purple {
        background: $dark-purple;
        color: rgba(255,255,255, 0.5);
        h1, h2, h3, h4, h5, h6, a {
            color: rgba(#fff, 0.8);
        }
        a {
            color: #fff;
        }
    }
    .blockstyle-semi-purple {
        background: $semi-purple;
        color: rgba(255,255,255, 0.5);
        h1, h2, h3, h4, h5, h6, a {
            color: rgba(#fff, 0.8);
        }
        a {
            color: #fff;
        }
    }
    .blockstyle-purple {
        background: $purple;
        color: rgba(255,255,255, 0.5);
        h1, h2, h3, h4, h5, h6, a {
            color: rgba(#fff, 0.8);
        }
        a {
            color: #fff;
        }
    }


/***** Style elements *****/
    .left {
        float: left;
    }
    .right {
        float: right;
    }
    .centered {
        display: block;
        margin: 0px auto;
    }

    .text-center {
        text-align: center;
    }

    .text-right {
        text-align: right;
    }

