/***** Custom reset *****/
	*, *:before, *:after {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	html, body {
		width: 100%;
		//height: 100%;
		//min-height: 100%;
	}

/***** Custom fonts *****/
	/* This stylesheet generated by Transfonter (http://transfonter.org) on January 24, 2017 11:11 AM */

	@font-face {
		font-family: 'Comfortaa';
		src: url('../fonts/Comfortaa-Light.eot');
		src: local('Comfortaa Light'), local('Comfortaa-Light'),
			url('../fonts/Comfortaa-Light.eot?#iefix') format('embedded-opentype'),
			url('../fonts/Comfortaa-Light.woff2') format('woff2'),
			url('../fonts/Comfortaa-Light.woff') format('woff'),
			url('../fonts/Comfortaa-Light.ttf') format('truetype'),
			url('../fonts/Comfortaa-Light.svg#Comfortaa-Light') format('svg');
		font-weight: 300;
		font-style: normal;
	}

	@font-face {
		font-family: 'Comfortaa';
		src: url('../fonts/Comfortaa-Regular.eot');
		src: local('Comfortaa'), local('Comfortaa-Regular'),
			url('../fonts/Comfortaa-Regular.eot?#iefix') format('embedded-opentype'),
			url('../fonts/Comfortaa-Regular.woff2') format('woff2'),
			url('../fonts/Comfortaa-Regular.woff') format('woff'),
			url('../fonts/Comfortaa-Regular.ttf') format('truetype'),
			url('../fonts/Comfortaa-Regular.svg#Comfortaa-Regular') format('svg');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'Comfortaa';
		src: url('../fonts/Comfortaa-Bold.eot');
		src: local('Comfortaa Bold'), local('Comfortaa-Bold'),
			url('../fonts/Comfortaa-Bold.eot?#iefix') format('embedded-opentype'),
			url('../fonts/Comfortaa-Bold.woff2') format('woff2'),
			url('../fonts/Comfortaa-Bold.woff') format('woff'),
			url('../fonts/Comfortaa-Bold.ttf') format('truetype'),
			url('../fonts/Comfortaa-Bold.svg#Comfortaa-Bold') format('svg');
		font-weight: bold;
		font-style: normal;
	}

/***** Imports *****/
	@import 'general';

/***** Basic styling *****/
	body {
		position: relative;
		font-family: 'Lato', sans-serif;
		background: $nearly-white;
		color: $text;
		padding-top: 170px;
		overflow-y: scroll;
	}

/***** Logo *****/
	$logo-margin: 35px;
	$logo-width: 280px;

	#logo-wrapper {
		z-index: 20;
		display: block;
		position: fixed;
		top: 0%;
		left: 0%;
		margin-left: $logo-margin;
		width: $logo-width;
		border: 0px solid rgba(0,0,0, 0.3);
		border-top: 0px;
		border-radius: 0px 0px 6px 6px;
		background: #fff;
		box-shadow: 0px 1px 5px 0px rgba($text, 0.5);
	}
	#logo {
		display: block;
		width: 100%;
	}
	#logo-logo {
		display: inline-block;
		text-decoration: none;
		padding: 25px 35px;

		> img {
			border: 0px;
			width: 100%;
			height: auto;
		}
	}

/***** Socials *****/
	#socials {
		z-index: 15;
		position: fixed;
		top: 15px;
		right: 15px;
		//width: 200px;
		display: inline-block;
	}
	#socials > a {
		display: inline-block;
		width: 32px;
		height: 32px;
		color: transparent;
		overflow: hidden;
		font-size: 32px;
	}
	#socials > a:before {
//		color: rgba($beige, 0.5);
	}
	#socials > a:hover:before, #socials > a:focus:before {
//		color: $beige;
	}

/***** Menu *****/
	$menu-first-height: 46px;
	$menu-second-height: 68px;

	#menu-wrapper {
		z-index: 15;
		position: fixed;
		display: block;
		width: 100%;
		left: 0%;
		height: ($menu-first-height + $menu-second-height);
		top: 0%;

		&:before {
			z-index: -1;
			content: '';
			display: inline-block;
			width: 100%;
			position: absolute;
			top: 0px;
			left: 0px;
			background: $semi-purple;
			height: $menu-first-height;
		}

		&:after {
			z-index: -1;
			content: '';
			display: inline-block;
			width: 100%;
			position: absolute;
			top: $menu-first-height;
			left: 0px;
			background: $dark-purple;
			height: $menu-second-height;
		}

		> a {
			display: none;
		}
	}
	#menu {
		z-index: 1;
		position: relative;
		display: block;
		width: 100%;
		padding-left: ($logo-margin + $logo-width);
		font-size: 0;
		transition: all 0.5s ease 0s;

		> .menu-item {
			// position: relative;
			display: block;
			width: 100%;

			// default menu's
			.menu {
				// position: relative;
				width: 100%;
				font-size: 0;
				display: inline-block;
				list-style: none;
				text-align: center;

				@media all and (min-width: 850px) {
					text-align: justify;
				}
			
				&:after {
					content: '.';
					display: inline-block;
					width: 100%;
					visibility: hidden;
					height: 0px;
					font-size: 0;
					line-height: 0;
				}

				.menu-item {
					display: inline-block;

					a {
					}
				}
			}

			// top-level menu's
			> .menu {
				height: 100px;
				
				> .menu-item {
					width: 100%;

					&:nth-child(1) {
						height: $menu-first-height;
						overflow: hidden;

						.menu {
							text-align: right;
						}
						.menu, .menu-item {
							height: inherit;

							> a {
								height: inherit;
								padding: 16px 20px;
								font-size: 0.9rem;
								text-transform: uppercase;
								font-weight: 300;
								color: rgba(255,255,255, 0.5);
							}
						}
					}
					
					&:nth-child(2) {
						height: $menu-second-height;
						padding: 0px 30px;

						> .menu {
							height: inherit;

							> .menu-item {
								height: inherit;

								> a {
									z-index: 1;
									height: inherit;
									padding: 22px 20px;
									font-size: 1.3rem;
									color: rgba(255,255,255, 0.6);
								}

								&.on, &.semi, &:hover, &:focus {
									> a {
										color: #fff;
									}
								}

								// Submenu's
								> .menu {
									z-index: 2;
									opacity: 0;
									pointer-events: none;
									position: absolute;
									top: ($menu-first-height + $menu-second-height);
									left: 0px;
									background: $dark-purple;
									padding: ($logo-margin + 10) 50px $logo-margin;
									box-shadow: 0px 3px 3px 0px rgba($text, 0.5);
									transition: all 0.35s ease;

									.menu-item {
										a {
											color: rgba(#fff, 0.4);
											font-size: 1.02rem;
										}
									}
								}
								&:hover, &:focus {
									background: $semi-purple;
									
									> a {
										z-index: 3;
									}

									> .menu {
										pointer-events: auto;
										opacity: 1;
									}
								}
							}
						}
					}
				}
			}

		}
	}

	.menu-item > a {
		display: inline-block;
		text-decoration: none;
		padding: 0px 20px;
		font-size: 1.2rem;
		line-height: 1;
	
		@media all and (min-width: 850px) {
		}

		&:hover, &:focus {
			color: #fff !important;
		}
	}


/***** Content *****/
	.page {
		position: relative;
		display: block;
		width: 100%;
		max-width: 1020px;
		margin: 0px auto;
		// left: 50%;
		// transform: translate(-50%, 0%);
		// top: 170px;
		// background-color: rgba($dark-brown, 0.5);
		// overflow: hidden;
		// height: 0%;
		opacity: 1;
	}

	#footer {
		opacity: 0;
		//border-top: 8px solid $dark-purple;
		//background: $semi-purple;
		border-top: 8px solid #7f8c8d;
		background: #bdc3c7;
		width: 100%;
		display: inline-block;

		> div {
			display: block;
			width: 100%;
			max-width: 1020px;
			margin: 0px auto;
			//padding: 20px;
			color: rgba(255,255,255,.5);

			a {
				color: rgba(255,255,255,.5);

				&:hover, &:focus {
					color: rgba(255,255,255,.8);
				}

				&.social-link {
					//margin-right: 20px;

					> i {
						font-size: 28px;
						margin-right: 8px;
					    vertical-align: -15%;
					}
				}
			}
		}
	}

	#vhw {
		width: auto;
		position: fixed;
		bottom: 10px;
		left: 100%;
		margin-left: -28px;
		transform: translate(0%, 0%);
		height: 24px;
		white-space: nowrap;
		color: rgba(#fff, 0.3);
		text-decoration: none;
		transition: all 0.35s ease;
		padding-right: 5px;

		img {
			margin-right: 5px;
			border: 0px;
			vertical-align: -30%;
			opacity: 0.3;
		}

		&:hover, &:focus {
			//margin-left: 0px;
			transform: translate(-100%, 0%);
			color: rgba(#fff, 0.5);
			// color: $purple;
			
			img {
				opacity: 0.5;
			}
		}
	}

/***** Status & Form *****/
    .statusbox {
        width: 100%;
        display: inline-block;
        margin-bottom: 30px;
        padding: 15px;

        &.ok {
            border: 2px solid #4CAF50;
            color: #4CAF50;
        }
        &.warning {
            border: 2px solid #FB8C00;
            color: #FB8C00;
        }
        &.error {
            border: 2px solid #D32F2F;
            color: #D32F2F;
        }
    }


    input, select, textarea, button, .button {
        &:not([class*="cke_"]) {
            display: inline-block;
            padding: 10px 15px;
            font-size: 1.1rem;
            outline: none;
            vertical-align: top;
            color: rgba(0,0,0, 0.54);
        }
        &[type="file"] {
            background: #fff;
        }
    }
    input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]), select, textarea {
        &:not([class*="cke_"]) {
            width: 100%;
        }
    }
    textarea {
        &:not([class*="cke_"]) {
           height: 85px;
       }
    }
    input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]), select, textarea {
        &:not([class*="cke_"]) {
            border: 1px solid rgba(0,0,0, 0.3);
            box-shadow: inset 0px 1px 2px 0px rgba(0,0,0, 0.3);

            &:focus {
                border: 1px solid rgba(#1E88E5, 1);
                box-shadow: inset 0px 1px 2px 0px rgba(0,0,0, 0.3),
                    0px 0px 2px 1px rgba(#03A9F4, 0.8);
            }
        }
    }

    input[type="checkbox"], input[type="radio"] {
        width: 0px;
        height: 0px;
        opacity: 0;
        padding: 0px;
        margin: 0px;

        & + label {
            display: inline-block;
            position: relative;
            height: 20px;
            padding-left: 30px;
            font-size: 1.01rem;
            outline: none;
            margin-right: 30px;

            &:before {
                content: '';
                position: absolute;
                z-index: 2;
                top: 0px;
                left: 0px;
                width: 20px;
                height: 20px;
                background: #fff;
                border: 1px solid rgba(0,0,0, 0.3);
                box-shadow: inset 0px 1px 2px 0px rgba(0,0,0, 0.3);
            }

            &:after {
                content: '';
                position: absolute;
                z-index: 3;
                opacity: 0;
                transition: all 0.15s ease 0s;
                top: 2px;
                left: 6px;
                width: 8px;
                height: 13px;
                border-bottom: 4px solid #1976D2;
                border-right: 4px solid #1976D2;
                transform: rotate(45deg);
                transform-origin: 50% 50%;
            }
        }
        &[type="radio"] + label {
            &:before, &:after {
                border-radius: 50%;
            }
            &:after {
                top: 5px;
                left: 5px;
                width: 10px;
                height: 10px;
                border: 0px;
                transform: rotate(0deg);
                transform-origin: 50% 50%;
                background: #1976D2;
            }
        }

        &:hover + label {
            //
        }

        &:focus + label {
            &:before {
                border: 1px solid rgba(#1E88E5, 1);
                box-shadow: inset 0px 1px 2px 0px rgba(0,0,0, 0.3),
                    0px 0px 2px 1px rgba(#03A9F4, 0.8);
            }
        }

        &[type="checkbox"]:checked + label:after {
            opacity: 1;
        }

        &[type="radio"]:checked + label:after {
            opacity: 1;
        }
    }

    button, .button, input[type="submit"] {
        &:not([class*="cke_"]) {
            position: relative;
            cursor: pointer;
            background: #F5F5F5;
            color: rgba(0,0,0, 0.54);
            border: 1px solid rgba(0,0,0, 0.1);
            box-shadow: inset 0px 1px 0px 0px rgba(255,255,255, 0.3), 0px 1px 3px -1px rgba(0,0,0, 0.4);
            border-radius: 3px;
            text-decoration: none;
            padding: 12px 15px;
            height: 45px;

            &:hover, &:focus {
                background: #FAFAFA;
            }
            &:active {
                background: #E0E0E0;
                padding-top: 13px;
                padding-bottom: 11px;
                box-shadow: inset 0px 1px 1px 0px rgba(0,0,0, 0.1);

                .fa {
                    margin-top: -9px;
                }
            }

            &.ico {
                padding-left: 37px;
            }
            &.yellow {
                background: #FFA726;

                &:hover, &:focus {
                    background: #FFB74D;
                }
                &:active {
                    background: #FB8C00;
                }
            }
            &.light-blue {
                background: #B3E5FC;

                &:hover, &:focus {
                    background: #E1F5FE;
                }
                &:active {
                    background: #4FC3F7;
                }
            }
            &.add {
                background: #4CAF50;

                &:hover, &:focus {
                    background: #66BB6A;
                }
                &:active {
                    background: #388E3C;
                }
            }
            .fa {
                position: absolute;
                top: 50%;
                margin-top: -10px;
                left: 12px;
                display: inline-block;
                width: 20px;
                height: 20px;
                font-size: 20px;
            }
        }
    }

    .form-label {
        display: inline-block;
        width: 100%;
        padding: 10px 0px 5px;
        font-size: 1.1rem;
       
        &.error {
            color: #D32F2F;
        }
    }

    .form-input {
        display: inline-block;
        width: 100%;
        padding: 5px 0px 20px;
    }


/***** Responsiveness *****/
	@media all and (max-width: 1023px) {

		img {
			max-width: 100%;
			height: auto !important;
		}

		#logo-wrapper {
			margin-left: 15px;
			width: 220px;

			#logo-logo {
				padding: 15px 25px;
			}
		}
		#menu-wrapper {
			height: 80px;

			&:before {
				height: 25px;
			}
			&:after {
				top: 25px;
				height: 55px;
			}

			> a {
				z-index: 2;
				display: inline-block;
				position: absolute;
				top: 15px;
				right: 15px;
				width: 50px;
				height: 50px;
				font-size: 32px;
				color: rgba(#fff, 0.6);
				background: $semi-purple;
				text-align: center;
				padding: 5px 10px;
				border-radius: 4px;
			}

			#menu {
				z-index: 1;
				position: fixed;
				top: 80px;
				bottom: 0px;
				width: auto;
				left: 0px;
				right: -30px;
				padding: 0px;
				padding-right: 30px;
				background: rgba($semi-purple, 0.8);
				overflow-x: hidden;
				overflow-y: scroll;
				-webkit-overflow-scrolling: touch;
				opacity: 0;
				pointer-events: none;

				&.open {
					opacity: 1;
					pointer-events: all;
				}

				&:before {
					content: '';
					position: absolute;
					top: 0px;
					right: 0px;
					width: 100%;
					max-width: 430px;
					height: 100%;
					background: $dark-purple;
				}

				> li {
					position: relative;
					float: right;
					//top: 0px;
					//right: 0px;
					width: 100%;
					max-width: 400px;

					ul, li, a {
						width: 100% !important;
					}
					> ul > li {
						height: auto;
						overflow: visible;
						background: $dark-purple;
						padding: 0px;

						a {
							padding: 18px 20px;
							font-size: 1.2rem;
						}

						&:nth-child(1) {
							background: $semi-purple;
							
							a {
								padding: 15px 20px;
								font-size: 1.0rem;
								text-align: center;
							}
						}

						> ul > li {
							> ul {
								position: relative;
								top: 0px;
								opacity: 1;
								height: 0px;
								overflow: hidden;
								padding: 0px;
							}

							&.on > ul {
								height: auto;
								box-shadow: none;

								&:before {
									content: '';
									position: absolute;
									top: 0px;
									left: 0px;
									width: 15px;
									height: 100%;
									background: $dark-purple;
								}

								li {
									padding-left: 15px;
									border-right: 15px solid $dark-purple;
									background: $semi-purple;
								}
							}
						}
					}
				}
			}
		}
	}

	@media all and (max-width: 500px) {
		#logo-wrapper {
			width: 170px;

			#logo-logo {
				padding: 10px 15px;
			}
		}
		#menu-wrapper {
			#menu {
				&:before {
					max-width: 100%;
				}
				> li {
					max-width: 100%;
				}
			}
		}
	}

	@media all and (max-width: 424px) {
		#vhw {
			visibility: hidden;
		}
	}


/***** Extra adjustments *****/
	@media all and (min-width: 1024px) {
		li.menu-item-40 {
			position: absolute;
			width: 56px !important;
			height: 46px !important;
			overflow: hidden !important;
			margin-left: -56px;

			> a {
				color: transparent;
				padding-left: 100px !important;
				position: relative;
				//overflow: hidden !important;

				&:before {
					position: absolute;
					top: 15px;
					left: 20px;
					content: "\f015";
					display: inline-block;
					font: normal normal normal 14px/1 FontAwesome;
					font-size: 18px;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
				}
			}
		}
	}

	@media all and (max-width: 768px) {
		#footer > div > div {
			text-align: center;

			&:last-child {
				width: 100% !important;
				text-align: center;
			}
		}
	}
	@media all and (max-width: 450px) {
		#footer > div > div:nth-child(2) {
			padding: 0px;
		}
	}